import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Link from '@/components/atoms/Link';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { CheckAvailabilityButton } from '@/components/molecules/CheckAvailabilityButton';
import { CarTypes } from '@/components/molecules/GVECalculator/GVECalculator';
import { HeartButton } from '@/components/molecules/HeartButton';
import { InfoDisplay } from '@/components/molecules/InfoDisplay';
import { ProductListingLinkFooter } from '@/components/molecules/ProductListingLinkFooter/ProductListingLinkFooter';
import { VLPCardImageContainer } from '@/components/molecules/VLPCardImageContainer/VLPCardImageContainer';
import { CallModal } from '@/components/organisms/CallModal';
import { useSavedVehicles } from '@/context/SavedVehicles';
import { useAuth } from '@/hooks/useAuth';
import { useContactDealer } from '@/hooks/useContactDealer';
import { useIsViewed } from '@/hooks/useViewedVehicle';
import {
  DEFAULT_COSTS,
  GAS_SAVINGS_INITIAL_TERM,
  GOOGLE_TRACK_INFO,
  PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION,
} from '@/lib/constants';
import { getFuelCosts } from '@/lib/fuelCost/getFuelCosts';
import {
  findPurchaseFederalIncentive,
  getTotalIncentiveAmount,
  getTotalRebateAmount,
  returnPurchaseFederalIncentive,
} from '@/lib/incentiveUtils';
import { JDPowerIncentive } from '@/lib/schema/incentives/types';
import {
  VehicleBadgeIds,
  VehicleListingType,
} from '@/lib/schema/inventory/types';
import { IndividualSavedVehicleProps } from '@/lib/schema/user/types';
import { showToast } from '@/lib/toast';
import { FuelTypes } from '@/types/enums';
import { FilterState } from '@/types/filters';
import clsx from 'clsx';
import { useRouter } from 'next/compat/router';
import React, { ForwardedRef, useCallback, useMemo, useState } from 'react';
import { BaseProductListingLinkProps } from '../LoadingCards/LoadingCards';
import { VehiclePrice } from '../VehiclePrice/VehiclePrice';

interface ProductListingLinkProps
  extends BaseProductListingLinkProps,
    IndividualSavedVehicleProps {
  product: VehicleListingType;
  isListedPrice: boolean;
  isMobile: boolean;
  filters?: FilterState;
  showNewCheckAvailabilityButton: boolean;
  contactedDealer: boolean;
  showFooter?: boolean;
}

function ListingLink(
  {
    product,
    isColumnLayout = false,
    isSaved: initiallySaved,
    isListedPrice,
    isMobile,
    filters,
    showNewCheckAvailabilityButton,
    contactedDealer,
    showFooter = true,
  }: ProductListingLinkProps,
  ref: ForwardedRef<HTMLDivElement> | null
) {
  const [isCheckAvailabilityDialogOpen, setIsCheckAvailabilityDialogOpen] =
    useState(false);
  const router = useRouter();
  const [showCallModal, setShowCallModal] = useState(false);
  const { images, price, make, year, model, trim, dealer, vin, listingId } =
    product;
  const dealerInfo = useMemo(
    () => ({
      City: dealer?.city || '',
      State: dealer?.province || '',
      Dealership: dealer?.name || '',
      Phone: dealer?.phone || '',
      DealerID: dealer?.dealerID || '',
      PostalCode: dealer?.postalCode || '',
    }),
    [dealer]
  );

  const { stateIncentives, localIncentives, federalIncentives } = useMemo(
    () => ({
      stateIncentives: product.incentives?.State || [],
      localIncentives: product.incentives?.local || [],
      federalIncentives: returnPurchaseFederalIncentive(
        product.incentives?.Federal || []
      ),
    }),
    [product.incentives]
  );

  const { savingsPerYear: estimatedFuelSavingsPerYear } = getFuelCosts({
    carType: product.body || CarTypes.Sedan,
    electricityCost: Number(DEFAULT_COSTS.electricityCost),
    gasolineCost: Number(DEFAULT_COSTS.gasolineCost),
    mileagePerYear: Number(DEFAULT_COSTS.mileagePerYear),
    years: Number(GAS_SAVINGS_INITIAL_TERM),
    fuelType: product.fuelType === 'Hybrid' ? 'Hybrid' : 'Electric',
  });

  const vehicleId = product.listingId;
  const badges = product?.badges;

  const hasIncentives =
    stateIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    localIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    federalIncentives.some((incentive: JDPowerIncentive) =>
      findPurchaseFederalIncentive(incentive)
    );

  const allIncentives = [
    ...stateIncentives,
    ...localIncentives,
    ...federalIncentives,
  ];
  const monetaryIncentiveAmount = getTotalIncentiveAmount(allIncentives);
  const rebateAmount = getTotalRebateAmount(allIncentives);

  const hasStateLocalButNoFederalBadge =
    hasIncentives &&
    !badges.includes('ELIGIBLE FOR FEDERAL INCENTIVES') &&
    filters?.fields.has_ev_incentives === true;

  const {
    saved,
    saveVehicle,
    loading: savingVehicle,
  } = useSavedVehicles({
    storeField: 'listing',
    initiallySaved,
    infoToSave: {
      id: vehicleId,
      price: price.displayValue,
      year,
      make,
      model,
      trim,
      image: images[0],
    },
  });

  const { user } = useAuth();

  const infoBlocks: { text: string; postFix: string }[] = useMemo(() => {
    const result: { text: string; postFix: string }[] = [];

    if (product.mileage) {
      result.push({
        text: `${product.mileage.value}`,
        postFix: 'Miles',
      });
    }

    if (product.batteryInfo?.batterySize) {
      result.push({
        text: product.batteryInfo.batterySize,
        postFix: 'Battery size',
      });
    }

    if (product.rangeInfo) {
      result.push({
        text: `${product.rangeInfo.value} ${product.rangeInfo.unit}`,
        postFix: 'Est. Range',
      });
    }

    return result;
  }, [product]);

  const handleClickHeartButton = useCallback(
    async (
      e?:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      if (e) {
        e.stopPropagation();
      }
      await saveVehicle(vehicleId);
    },
    [saveVehicle, vehicleId]
  );

  const { contactDealer } = useContactDealer({
    product,
    dealer,
    user,
    incentivesData: {
      federal: federalIncentives,
      local: localIncentives,
      state: stateIncentives,
    },
    estimatedFuelSavingsPerYear,
  });

  const handleMessageDealer = useCallback(async () => {
    await contactDealer({
      onSuccess: async () => {
        if (!saved) {
          await saveVehicle(vehicleId, { hideToast: true });
        }
      },
    });
  }, [contactDealer, saveVehicle, saved, vehicleId]);

  const handleEmailNotification = async () => {
    try {
      const response = await fetch('/api/partner/email_notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          VIN: vin,
          Make: make,
          Model: model,
          Trim: trim,
          Year: year.toString(),
          Price: price.value?.toString(),
          DealershipName: dealer?.name,
          ListingId: listingId,
          ...(user && { CustomerEmail: user.email }),
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to send email notification');
      }
    } catch (error) {
      showToast('Failed to send email notification.', {
        type: 'error',
      });
    }
  };

  const { isViewed } = useIsViewed(product.listingId);

  const cta = (
    <section className="flex w-full justify-center gap-l">
      <div className="flex w-full shrink-2">
        <Button
          aria-label="Call dealer"
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await handleEmailNotification();
            if (isMobile) {
              window.open(`tel: ${dealer?.phone}`, '_self');
            } else {
              setShowCallModal(!showCallModal);
            }
          }}
          analyticsEvent={{
            ...GOOGLE_TRACK_INFO.callDealerButton,
            pageTarget: dealer?.phone
              ? `/vehicle/${product.listingId}?dealerPhone=${dealer.phone}`
              : `/vehicle/${product.listingId}`,
          }}
          variant={ButtonVariants.Secondary}
          size="small"
        >
          Call
        </Button>
      </div>
      <div className="w-full">
        {showNewCheckAvailabilityButton ? (
          <CheckAvailabilityButton
            backGroundImage={product.images[0]}
            messageDealer={async () => {
              await handleMessageDealer();
            }}
            contactedDealer={contactedDealer}
            isAuthDialogOpen={isCheckAvailabilityDialogOpen}
            setIsAuthDialogOpen={setIsCheckAvailabilityDialogOpen}
            size="small"
            listingId={product.listingId}
          />
        ) : (
          <Button
            aria-label="Message"
            size="small"
            onClick={async (e) => {
              e.preventDefault();
              await router?.push({
                pathname: `/vehicle/[listingId]`,
                query: {
                  listingId: product.listingId,
                  scrollTo: 'form',
                },
              });
            }}
            variant={ButtonVariants.Tertiary}
          >
            Message
          </Button>
        )}
      </div>
    </section>
  );

  const analyticsEvent = {
    ...GOOGLE_TRACK_INFO.vehicleDetailPageButton,
    pageTarget: `/listingId=${product.listingId}`,
  };
  const href =
    product.listingId || product.shortListingId
      ? `/vehicle/${product.shortListingId || product.listingId}`
      : `/vehicle/by-vin/${product.vin}`;

  return (
    <div className="flex h-full w-full" ref={ref}>
      <div className="border-1 flex w-full flex-col rounded-lg border-[1px] border-neutral-200 shadow-md hover:shadow-lg">
        <div
          className={`group flex flex-1 flex-col rounded-lg bg-background-white ${
            isColumnLayout ? '' : 'm:flex-row'
          }`}
        >
          <section
            className={`flex overflow-hidden ${isColumnLayout ? 'rounded-t-lg' : 'rounded-l-lg md:basis-1/2'}`}
          >
            <Link
              aria-label={`link to vehicle ${product.year} ${product.make} ${product.model}`}
              analyticsEvent={analyticsEvent}
              href={href}
            >
              <div className="absolute z-10 flex p-m transition-opacity duration-150 ml:pointer-events-none ml:flex ml:opacity-0 group-hover:ml:pointer-events-auto group-hover:ml:opacity-100">
                <HeartButton
                  authDialogImage={product.images[0]}
                  size={'medium'}
                  saved={saved}
                  onClick={handleClickHeartButton}
                  disabled={savingVehicle}
                />
              </div>
              <VLPCardImageContainer
                images={product.images}
                make={product.make}
                model={product.model}
                year={String(product.year)}
                isMobile={isMobile}
                isColumnLayout={isColumnLayout}
                handleSaveVehicle={handleClickHeartButton}
                saved={saved}
                savingVehicle={savingVehicle}
                isViewed={isViewed}
              />
            </Link>
          </section>
          <section className="flex flex-1 flex-col">
            <Link
              aria-label={`link to vehicle ${product.year} ${product.make} ${product.model}`}
              analyticsEvent={analyticsEvent}
              href={href}
            >
              <div
                className={clsx('flex h-full w-full flex-col justify-between', {
                  'gap-l p-l md:flex-1 md:basis-3/5': isColumnLayout,
                  'px-l py-xl md:basis-1/2': !isColumnLayout,
                })}
              >
                {(badges.length > 0 ||
                  hasStateLocalButNoFederalBadge ||
                  filters?.fields.fuelType === FuelTypes.Hybrid ||
                  filters?.fields.condition === 'New') && (
                  <section className="flex justify-between">
                    <div className="flex flex-wrap gap-s">
                      {filters?.fields.condition === 'New' && (
                        <Badge
                          className="bg-neutral-200 text-neutral-800"
                          label={filters?.fields.condition}
                        />
                      )}
                      {filters?.fields.fuelType === FuelTypes.Hybrid && (
                        <Badge
                          className="!text-blue-dark"
                          label={PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION}
                          variant={BadgeVariants.Blue}
                        />
                      )}
                      {badges?.map((badge, i) => (
                        <Badge
                          key={`badge-${i}`}
                          variant={BadgeVariants.Blue}
                          label={badge}
                        />
                      ))}
                      {hasStateLocalButNoFederalBadge && (
                        <Badge
                          variant={BadgeVariants.Blue}
                          label={
                            VehicleBadgeIds['ELIGIBLE FOR FEDERAL INCENTIVES']
                          }
                        />
                      )}
                    </div>
                  </section>
                )}
                <section className="flex items-center justify-between">
                  <div
                    className={clsx('flex w-full gap-xs', {
                      'flex-col': isColumnLayout,
                    })}
                  >
                    <h2 className="overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-body1Regular text-neutral-900 group-hover:text-blue-medium group-hover:underline">
                        {product.year} {product.make} {product.model}{' '}
                      </span>
                      <span className="text-body1Light text-neutral-800 group-hover:text-blue-medium group-hover:underline">
                        {product.trim}
                      </span>
                    </h2>
                    <div
                      className={clsx('text-body2Light text-neutral-600', {
                        hidden: !isColumnLayout,
                      })}
                    >
                      {product.condition === 'Used' ? 'Pre-Owned' : 'New'}・
                      {product.mileage?.value} {product.mileage?.unit}
                    </div>
                  </div>
                </section>

                <section
                  className={`flex flex-col ${isColumnLayout ? 'hidden' : ''}`}
                >
                  <div className="flex h-full flex-row">
                    {infoBlocks.map(({ text, postFix }, i) => (
                      <InfoDisplay
                        key={i}
                        className={`px-4 ${
                          i !== infoBlocks.length - 1
                            ? 'border-r border-[#E5E7EB]'
                            : ''
                        } ${i === 0 && !isColumnLayout ? 'md:px-0 md:pr-m' : ''}`}
                        textContainerClassName="flex-col items-start justify-center"
                        main={{
                          text,
                          postFix,
                          className: 'text-body2Medium',
                          postFixClassName: 'text-microLight !pl-0',
                        }}
                      />
                    ))}
                  </div>
                </section>

                <section
                  className={clsx('flex justify-between gap-l', {
                    'flex-row items-center': !isColumnLayout,
                    'flex-col items-start': isColumnLayout,
                  })}
                >
                  <VehiclePrice
                    isListedPrice={isListedPrice}
                    price={price.value}
                    appliedIncentiveTotal={
                      monetaryIncentiveAmount + rebateAmount
                    }
                    totalEstFuelSavingsPerYear={estimatedFuelSavingsPerYear}
                    gasTerm={Number(GAS_SAVINGS_INITIAL_TERM)}
                    variant={'VehicleCard'}
                  />
                  <div className="flex w-full flex-1 justify-start">{cta}</div>
                </section>
              </div>
            </Link>
            {showFooter && (
              <div
                className={`flex flex-row items-center justify-evenly border-t border-neutral-200 ${
                  isColumnLayout ? 'mt-auto' : ''
                }`}
              >
                <ProductListingLinkFooter
                  aria-label={`link to dealer ${dealerInfo}`}
                  dealerInfo={dealerInfo}
                  isColumnLayout={isColumnLayout}
                  filters={filters}
                />
              </div>
            )}
          </section>
        </div>
        {dealer && (
          <CallModal
            open={showCallModal}
            onClose={() => {
              setShowCallModal(false);
              if (!user) {
                setTimeout(() => {
                  setIsCheckAvailabilityDialogOpen(true);
                }, 1000);
              }
            }}
            product={product}
          />
        )}
      </div>
    </div>
  );
}

export const ProductListingLink = React.forwardRef(ListingLink);
